/* If you need to add @import statements, do so up here */

@import "jit-refresh.css"; /* triggers frontend rebuilds */

/* Set up Tailwind imports */
@tailwind base;
@tailwind components;
@tailwind utilities;

/* * {
  border: 1px solid red;
} */

* {
  scroll-behavior: smooth;
  transition: all 0.5s ease-in-out;
}

:root {
  --body-background: bg-background;
  --body-color: theme('colors.slate.1400');
}

body {
  background: var(--body-background);
  color: var(--body-color);
  font-family: "epilogue", sans-serif;
  font-size: 16px;
}

/* Initially hide the mobile menu */
#mobile-menu {
  max-height: 0;
  overflow: hidden;
  transition: max-height 0.3s ease-in-out;
}

/* Photo / Image Helpers */
.parent:nth-child(odd) {
  .offerings_images {
    @apply rounded-t-3xl lg:rounded-t-none lg:rounded-r-3xl overflow-hidden;
    .top_image {
      @apply max-h-52 lg:max-h-full lg:rounded-bl-[50px];
    }
    .bottom_image {
      @apply lg:rounded-tl-[50px];
    }
  }
}

.parent:nth-child(even) {
  .offerings_images {
    @apply rounded-t-3xl lg:rounded-t-none lg:rounded-l-3xl overflow-hidden;
    .top_image {
      @apply max-h-52 lg:max-h-full lg:rounded-br-[50px];
    }
    .bottom_image {
      @apply lg:rounded-tr-[50px];
    }
  }
}


.row_images {
  @apply flex flex-row w-full gap-4 h-44 lg:h-96 mask-gradient-center;
  img {
    @apply inline-block h-auto object-cover aspect-auto;
  }
  .first { @apply rounded-r-xl md:rounded-r-3xl bg-blush-300 w-full md:w-1/3 lg:w-5/12 flex-auto ;}
  .mid { @apply hidden md:flex rounded-xl md:rounded-3xl bg-blush-300 flex-auto w-full md:w-1/3 lg:w-3/12 h-auto;}
  .last { @apply hidden md:flex rounded-l-xl md:rounded-l-3xl bg-blush-300 flex-auto w-full md:w-1/3 lg:w-4/12 h-auto;}
}

/* end */

@layer components {

  .sub-nav {
    @apply flex flex-row justify-start gap-6 lg:gap-10 mt-8 px-4 lg:px-10 mx-auto overflow-x-auto lg:overflow-x-hidden max-w-full lg:max-w-[1480px] bg-gradient-to-b from-transparent from-[90%] to-[90%] to-oldrose-200;
    .nav-item {
      @apply text-sm lg:text-base font-medium text-center self-center pb-2 border-b-4 border-transparent lg:border-oldrose-200 hover:border-blush-400 leading-none;
      &.active { @apply text-blush-1200 border-blush-800; }
    }
  }

  .section-content {
    opacity: 0;
    transition: opacity 0.5s ease-in-out, visibility 0.5s ease-in-out, height 0.5s ease-in-out;
    visibility: hidden; /* Hidden by default */
    height: 0;
    overflow: hidden;
  }

  .section-content.active {
    opacity: 1;
    visibility: visible;
    height: auto; /* Expand to content size */
  }
}

@layer utilities {

  .main-nav {
    .nav-item {
      @apply md:py-6 md:px-8 flex text-nowrap hover:bg-gradient-to-b from-oldrose-50 from-[94%] to-[94%] to-blush-800;
    }
  }


  /* Button Styles */
  .btn {
    @apply inline-block text-center rounded-full text-[18px] h-fit pt-2 pb-1 px-6 text-nowrap w-full md:w-fit hover:ease-in-out duration-300;
    white-space: pre;
  }
  
  .btn-primary {
    @apply bg-blush-1200 text-white hover:bg-blush-1400 ring ring-blush-1200 hover:ring-offset-2 ring-offset-inherit;
  }

  .btn-secondary {
    @apply hover:bg-blush-100 hover:text-blush-1400 border-2 border-blush-1200 text-blush-1200 hover:ring ring-blush-1200 ring-offset-2 ring-offset-inherit flex flex-row items-center justify-center h-fit;
    white-space: pre;
    &.-white {
      @apply text-white border-white hover:text-blush-1200;
    }
    span {
      @apply flex;
      svg {
        @apply object-cover h-6 w-6 -mt-1;
      }
    }
  }
  
  .btn-ghost {
    @apply pt-2 pb-1 px-6 text-blush-1300 text-[18px] rounded-full hover:ease-in-out duration-300 hover:ring hover:ring-blush-300 hover:ring-offset-2 ring-offset-blush-100;
  }
  
  .btn-link {
    @apply flex flex-row items-start text-[18px] font-medium text-blush-1300 hover:text-blush-1100 gap-2 hover:underline underline-offset-4 ease-in-out duration-300 min-w-fit;

    &.arrow::after {
      content: url('/images/svg/ArrowRight.svg');
      @apply h-[32px] w-[32px] -mt-1 object-cover;  
    }
  }
  
  .button-group {
    @apply flex flex-row flex-wrap divide-y xl:divide-y-0 divide-x border divide-oldrose-300 rounded-lg overflow-hidden;
    .btn-item {
      @apply pt-4 pb-3 px-4 text-slate-1200 hover:text-blush-1000 hover:bg-oldrose-100 flex-1 font-medium leading-tight ease-in-out duration-200;
    }
    .selected { @apply bg-blush-1200 text-white; }
  }
  /* end */


  .section-title {
    @apply text-2xl lg:text-3xl py-1 px-4 uppercase font-display w-fit;
    &.blush { @apply bg-blush-100 text-blush-1000; }
    &.slate { @apply bg-slate-300 text-slate-1400; }
    &.oldrose { @apply bg-oldrose-200 text-oldrose-1000; }
    p { @apply px-8 md:px-16 text-white text-base md:text-xl max-w-prose;}
  }

  .page-title {
    @apply text-3xl xl:text-6xl px-1 pl-4 lg:pl-16 pr-4 lg:pr-20 uppercase font-display w-fit text-nowrap;
    &.blush { @apply bg-blush-100 text-blush-1000; }
    &.slate { @apply bg-slate-1300 text-white; }
    &.oldrose { @apply bg-oldrose-200 text-oldrose-1000; }
  }

  /* Styling from Markdown */
  .bio, .description {
    p { @apply mb-4 last:mb-0 }
  }

  .markdown {
    ul {
      @apply ml-4 list-disc list-outside mb-4;
      li { @apply mb-2 last:mb-0;
        ul {
          li::marker {
            content: "○ ";
            
          }
        }
      }
    }
    ol {
      @apply ml-4 list-decimal list-outside mb-2;
      li {
        @apply mb-2 last:mb-0;
      }
    }
    img { @apply max-w-lg h-auto mx-auto; }
    p { @apply mb-4 last:mb-0;}
    h1 { @apply font-bold text-3xl mb-2;}
    h2 { @apply font-semibold text-2xl mb-2; }
    h3 { @apply font-medium text-xl mb-2; }
    h4 { @apply font-medium text-lg mb-2; }
    h5 { @apply font-normal text-base mb-2; }
    h6 { @apply font-normal text-sm mb-2; }
    blockquote { @apply pl-4 border-l-8 border-blush-800/50 font-semibold text-lg my-4 bg-white/50 pl-8 pr-6 py-6 rounded-xl leading-tight; }
    a { @apply text-blush-1200 hover:underline; }
    code { @apply bg-slate-1600 p-4 rounded-lg text-slate-200; }
  }

  .bullet-list {
    ul {
      @apply ml-4 list-disc list-outside;
      li { @apply mb-2 last:mb-0; }
    }
  }

  .section-list {
    @apply block p-8 bg-oldrose-50 rounded-xl mt-6;
    p { @apply font-semibold mb-2; }
    ul {
      @apply ml-4 list-disc list-outside;
      li { @apply mb-2 last:mb-0; }
    }
  }
  /* end */
 
  /* Fix for wonky font line height */
  .line-fix {
    position: relative;
    bottom: -0.15em;
  }
  /* end */
}

@layer base {

  .coach { @apply bg-blush-50 shadow-blush border-blush-100; }
  .events { @apply bg-blush-100 shadow-blush border-blush-100; }
  .facilitation { @apply bg-slate-100 shadow-slate border-slate-200; }
  .keynote { @apply bg-white shadow-neutral border-oldrose-100; }
  .training { @apply bg-white shadow-neutral border-oldrose-100; }
  .change { @apply bg-white shadow-neutral border-oldrose-100; }
  .communication { @apply bg-oldrose-50 shadow-blush border-blush-100; }
  .leadership { @apply bg-slate-100 shadow-slate border-slate-200; }
  .presenting { @apply bg-white shadow-neutral border-oldrose-100; }
  .team { @apply bg-blush-100 shadow-blush border-blush-100; }
  .women { @apply bg-oldrose-50 shadow-blush border-blush-100; }
  .workshops { @apply bg-oldrose-50 shadow-blush border-blush-100; }

  input, textarea {
    @apply text-base block w-full rounded-md border-0 bg-slate-50/50 pt-3 pb-2 pl-6 pr-20 text-slate-1400 ring-1 ring-inset ring-slate-500 placeholder:text-slate-800 focus:ring-2 focus:bg-white focus:ring-inset focus:ring-slate-800 sm:leading-6 outline-slate-800;
  }
  
  a {
    @apply focus:outline-blush-800 focus:outline-offset-2 w-fit;
  }

  .testimonial {
    @apply rounded-xl md:rounded-l-3xl flex flex-row gap-6 items-center justify-center md:bg-ripple bg-repeat-y bg-contain m-5 md:ml-8 xl:ml-48 shadow-blush;
    &.-homepage {
      @apply bg-blush-1400 text-white border border-blush-1400 bg-blend-color;
    }
    &.-offering {
      @apply bg-blush-100 text-blush-1300 bg-blend-color-dodge border border-blush-200;
      svg path {
        @apply fill-blush-1300;
      }
    }
  }


  .cta {
    @apply p-6 md:p-10 mx-5 mb-5 rounded-3xl flex flex-col bg-cover bg-center;
    &.-homepage {
      @apply bg-blush-1300 bg-crowd bg-blend-multiply text-white justify-start items-start xl:justify-end xl:items-end;
    }
    &.-offerings-blue {
      @apply bg-slate-1400 bg-postits bg-blend-multiply text-white justify-start items-start xl:justify-end xl:items-end mb-5;
    }
    &.-shop {
      @apply bg-blush-1600 backdrop-brightness-150 bg-crowd2 bg-blend-multiply text-slate-1400 text-white justify-start items-start xl:justify-end xl:items-end;
    }
    &.-about_us{
      @apply bg-blush-1400 backdrop-brightness-150 bg-crowd2 bg-blend-multiply text-slate-1400 text-white justify-start items-start xl:justify-end xl:items-end;
    }
  }
  
  .cta-2 {
    @apply md:mx-5 bg-blush-1500 md:bg-blush-1300 bg-burst bg-blend-soft-light bg-no-repeat bg-right-bottom p-8 md:px-16 py-12 rounded-3xl text-white;
    h5 {@apply text-2xl md:text-4xl font-semibold;}
    p {@apply text-lg max-w-3xl mb-8;}
  }
}